import request from '@/utils/request'


// 查询道具列表
export function listProp(query) {
  return request({
    url: '/prop/props/list',
    method: 'get',
    params: query
  })
}

// 查询道具分页
export function pageProp(query) {
  return request({
    url: '/prop/props/page',
    method: 'get',
    params: query
  })
}

// 查询道具详细
export function getProp(data) {
  return request({
    url: '/prop/props/detail',
    method: 'get',
    params: data
  })
}

// 新增道具
export function addProp(data) {
  return request({
    url: '/prop/props/add',
    method: 'post',
    data: data
  })
}

// 修改道具
export function updateProp(data) {
  return request({
    url: '/prop/props/edit',
    method: 'post',
    data: data
  })
}

// 删除道具
export function delProp(data) {
  return request({
    url: '/prop/props/delete',
    method: 'post',
    data: data
  })
}
